import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'
import messagePlugin from '@/utils/message.plugin'
import './registerServiceWorker'
import vmodal from 'vue-js-modal'
import 'materialize-css/dist/js/materialize.min'

import './assets/sass/app.scss'
import { firestorePlugin } from 'vuefire'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

Vue.config.productionTip = false

Vue.use(messagePlugin)
Vue.use(Vuelidate)
Vue.use(vmodal)
Vue.use(firestorePlugin)
Vue.filter('date', dateFilter)

firebase.initializeApp({
  apiKey: 'AIzaSyAvAtkIPvIgTuD48DpmUFD8cXhKviw_K9U',
  authDomain: 'vue-learn-b316f.firebaseapp.com',
  projectId: 'vue-learn-b316f',
  storageBucket: 'vue-learn-b316f.appspot.com',
  messagingSenderId: '79042811013',
  appId: '1:79042811013:web:001b2f5028e864eeecc5ec',
  databaseURL: 'https://vue-learn-b316f-default-rtdb.europe-west1.firebasedatabase.app'
})
// firebase.initializeApp({
//   apiKey: 'AIzaSyDUU8DTCe8OE1bNOCsMkPWNgiih9xSAYdE',
//   authDomain: 'normform-5de4a.firebaseapp.com',
//   databaseURL: 'https://normform-5de4a-default-rtdb.europe-west1.firebasedatabase.app',
//   projectId: 'normform-5de4a',
//   storageBucket: 'normform-5de4a.appspot.com',
//   messagingSenderId: '1058285330505',
//   appId: '1:1058285330505:web:2cf3d0a7b200cb2d67df98'
// })

let app

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
