<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import OldLayout from '@/layouts/OldLayout'
import NewLayout from '@/layouts/NewLayout'
import DemoLayout from '@/layouts/DemoLayout'
// import CryptoLayout from '@/layouts/CryptoLayout'

export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  components: {
    EmptyLayout,
    MainLayout,
    OldLayout,
    DemoLayout,
    NewLayout
    // ,CryptoLayout
  }
}
</script>

<style lang="scss">
@import '~materialize-css/dist/css/materialize.min.css';
@import 'assets/index.css';

</style>
